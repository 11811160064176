const idDesktop = window.innerWidth >= 1024;
const showOrHide = (el, op) => {
  if(op === 'show') {
    document.querySelector(el).classList.add('is-open');
    document.querySelector('.dropdown.layer-minicart') ? document.querySelector('.dropdown.layer-minicart').classList.remove('animate-minicart-closure') : null;
  } else {
    // document.querySelector('.dropdown.layer-minicart.is-open') ? document.querySelector('.dropdown.layer-minicart.is-open').classList.add('animate-minicart-closure') : null;
    if(document.querySelector(el)){
      document.querySelector(el).classList.remove('is-open');
    }
    // setTimeout(() => {
      // document.querySelector('.dropdown.layer-minicart') ? document.querySelector('.dropdown.layer-minicart').classList.remove('animate-minicart-closure') : null;
    // }, 2000);
  }
}

if(idDesktop) {
  $('.desktop-minicart-button').on('click', () => {
    const isMiniCartOpen = document.querySelector('.dropdown.layer-minicart.is-open');
    showOrHide('.layer-minicart #layer-minicart', isMiniCartOpen ? 'hide' : 'show');
  });
  $(document).on('click', (e) => {
    e.target.classList.contains('minicart-close') ? $('desktop-minicart-button').trigger('click') : null;
    if(!e.target.closest('#layer-minicart')) {
      showOrHide('.layer-minicart #layer-minicart', 'hide');
    }
  });
}


$(document).on('DOMNodeInserted', function(e) { 
  if($(e.target).hasClass('paypal-checkout-sandbox')){
    if(document.activeElement.parentElement.parentElement.classList.contains('paypal-cart-button-minicart')){
        fastCheckout('paypal', 'minicart');
    }
  }
});


$('body').on('click', '.apple-pay-minicart-button', function() {
  fastCheckout('applepay', 'minicart');
});


function fastCheckout(cartType, buttonPosition) {
  var fastCheckoutUrl = $(".track-fast-checkout-url").val();
  var fastCheckoutData = cartType + '_fast_' + buttonPosition;

  if(fastCheckoutUrl) {
    $.ajax({
      url: fastCheckoutUrl,
      method: 'GET',
      dataType: 'json',
      data: { fastPayment: fastCheckoutData},
      success: function (data) {
        $('body').trigger('checkout:fastCheckoutStart', [cartType, buttonPosition]);
      }
  });
 }
}
