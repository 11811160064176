const videoAmpObserver = new IntersectionObserver((entries) => {
  entries.forEach( entry => {
    if (entry.isIntersecting) {
      if(!entry.target.classList.contains('initialized')){
          if (typeof shaka !== 'undefined'){
              const videoElement  = entry.target.querySelector('video.shakamediaplayer');

              if (videoElement.classList.contains('pagedesigner')) {
                pageDesignerMobileVideoCheck(videoElement);
              }

              if (!videoElement.classList.contains('initialized')) {
                const url = isIOS() ? videoElement.getAttribute('data-ios-src') : videoElement.getAttribute('src');
                videoElement.closest('.mm-video-card').classList.add('initialized');
                initShaka(videoElement, url);
              }
          };
      } else {
          if (typeof shaka !== 'undefined'){
            const videoElement  = entry.target.querySelector('video.shakamediaplayer');
            videoElement.play();
          };
      }
    } else {
      if(entry.target.classList.contains('initialized')){
        if (typeof shaka !== 'undefined'){
          const videoElement  = entry.target.querySelector('video.shakamediaplayer');
          videoElement.pause();
        };
      }
    }
  });
});

const observeUninitializedVideos = function(){
  const videoTiles = document.querySelectorAll('.mm-video-card:not(.observed)');
  videoTiles.forEach((vt) => {
    vt.classList.add('observed');
    vt.addEventListener('mousewheel', function (e) {
      e.stopPropagation();
    }, true);
    videoAmpObserver.observe(vt)
  });
};

const einsteinObserver = new MutationObserver((mutationList, observer) => {
  // Use traditional 'for loops' for IE 11
  for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        observeUninitializedVideos();
      }
  }
});

const observeEinsteinCarousels = function(){
  const recommendSection = document.querySelectorAll('.recommendations');
  recommendSection.forEach(rs => einsteinObserver.observe(rs, {childList: true, subtree: true }));

}

const videoInit = () => {
  if (window.shaka) {
    var videoElements = document.querySelectorAll('.shakamediaplayer:not(.mm-tile-video-shaka)');
    shaka.polyfill.installAll();

    if (shaka.Player.isBrowserSupported()) {
      // Everything looks good!
      if (videoElements && videoElements.length) {
        videoElements.forEach((videoElement) => {
          if (videoElement.classList.contains('pagedesigner')) {
            pageDesignerMobileVideoCheck(videoElement);
          }

          if (!videoElement.classList.contains('initialized')) {
            let url = isIOS() ? videoElement.getAttribute('data-ios-src') : videoElement.getAttribute('src');
            if(!url){
              url = isIOS() ? videoElement.querySelector('source').getAttribute('data-ios-src') : videoElement.querySelector('source').getAttribute('src');
            }

            videoElement.classList.add('initialized');
            if (videoElement.closest('.mm-video-card')) {
              videoElement.closest('.mm-video-card').classList.add('initialized');
            }

            initShaka(videoElement, url);
          }
        });
      }
    } else {
      // This browser does not have the minimum set of APIs we need.
      console.error('Browser not supported!');
    }
  }

  observeUninitializedVideos();
  observeEinsteinCarousels();
  $('body').on('product:update-plp-cards wishlist:update-plp-cards', function() {
    observeUninitializedVideos();
  })
};

const pageDesignerMobileVideoCheck = (videoElement) => {
  const videoContainer = videoElement.closest('.mm-video-card');
  if (videoContainer) {
    var mobileVideo = videoContainer.querySelector(".mobile-video");
    if (window.innerWidth < 768 && mobileVideo) {
      if (mobileVideo.nextElementSibling) {
        if (mobileVideo.nextElementSibling.querySelector('video source')) {
          if (isIOS()) {
            mobileVideo.nextElementSibling.querySelector('video source').setAttribute("data-ios-src", mobileVideo.getAttribute("data-ios-src"));
          } else {
            mobileVideo.nextElementSibling.querySelector('video source').setAttribute("src", mobileVideo.getAttribute("data-src"));
          }
        } else {
          if (isIOS()) {
            mobileVideo.nextElementSibling.querySelector('video').setAttribute("src", mobileVideo.getAttribute("data-ios-src"));
          } else {
            mobileVideo.nextElementSibling.querySelector('video').setAttribute("src", mobileVideo.getAttribute("data-src"));
          }
        }

        if (window.innerWidth/window.innerHeight > 0.56) {
          mobileVideo.nextElementSibling.classList.toggle('resize');
        }
      }
    }
  }
}

const isIOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
};

async function initShaka(video, url) {

    try{
        if(video){
            video.muted=true;
        }
        const player = new shaka.Player();

        await player.attach(video);

        // Attach player to the window to make it easy to access in the JS console.
        window.player = player;

        // Listen for error events.
        player.addEventListener('error', onErrorEvent);

    }catch(e){
        console.log('error on attach video: '+url)
        console.log(e.detail)
    }
  // Try to load a manifest.
  // This is an asynchronous process.
  try {
    player.configure({
        mediaSource: {
            forceTransmux: true
        },
        streaming: {
          preferNativeHls : true
        }
    });
    await player.load(url);
    console.log(url);
    // This runs if the asynchronous load is successful.
    console.log('The video has now been loaded!');
  } catch (e) {
    video.classList.remove('initialized');
    video.classList.add('uninitialized');
    // onError is executed if the asynchronous load fails.
      console.error('Error on video initialize for video: '+url)
    onError(e);
  }

  function onErrorEvent(event) {
    // Extract the shaka.util.Error object from the event.
    onError(event.detail);
  }

  function onError(error) {
    // Log the error.
    console.error('Error code', error.code, 'object', error);
  }
}

module.exports = {
  videoInit: videoInit,
}
