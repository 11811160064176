const showPasswordButtons = document.querySelectorAll('.toggle-password.js-toggle-password');

const preventDefaultBehavior = (elements) => elements.forEach(el => el.addEventListener('click', (e) => e.preventDefault()));

const showFloatingLabelBehavior = () => {
  const formInputFields = document.querySelectorAll('.mm-form-input.-floating-label input:not(:placeholder-shown)');
  formInputFields.forEach((formInput) => {
    if(formInput) {
      formInput.classList.add('valid-input');
    }
  });
}

preventDefaultBehavior(showPasswordButtons);

showFloatingLabelBehavior();
