'use strict';

var debounce = require('lodash/debounce');
var endpoint = $('.suggestions-wrapper').data('url');
var minChars = 3;
var ENTER = 13;
var UP_KEY = 38;
var DOWN_KEY = 40;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;
var ESC = 27;
var isMobile = window.innerWidth <= 1024;
/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).siblings('.suggestions-wrapper-container').children('.left-container').children('.search-results').children('.suggestions-wrapper');
}

/**
 * Changes Search Container Size
 *
 */
function handleContainerSize(results) {
  var heightMobile;
  const menuWrapper =  $('.mm-menu__wrapper')[0];
  const suggestions =  $('.recommendations-container')[0];
  const leftContainerElement = $('.search-results')[0];
  var menu;
  var search;
  var leftContainer;
  var suggestionsWrapper;
  var heightMobileNoResults;
  leftContainer = $('.left-container')[0].offsetHeight;
  search = $('.mm-menu-search')[0].offsetHeight;
  var height = search + suggestions.offsetHeight + 60;
  var maxHeight = height - 48;
  if (isMobile) {
    menu = $('.mm-menu__primary')[0].offsetHeight;
    heightMobile = menu + search + leftContainer;
    heightMobileNoResults = menu + search + suggestions.offsetHeight + leftContainer + 60;
  }
  suggestionsWrapper = $('.suggestions-wrapper-container .page-search-result-container')[0].offsetHeight;
  var root = document.documentElement;
  if (maxHeight < 250) {
    root.style.setProperty('--search-results-max', "unset");
  } else {
    root.style.setProperty('--search-results-max', maxHeight + "px");
  }
  if (isMobile && results) {
    menuWrapper.style.paddingBottom = `clamp(350px, ${heightMobile}px, ${heightMobile}px)`;
  } else if (isMobile && !results) {
    menuWrapper.style.paddingBottom = `clamp(350px, ${heightMobileNoResults}px, ${heightMobileNoResults}px)`;
  } else {
      if ($('.search-is-open').length > 0) {
        menuWrapper.style.paddingBottom = `clamp(400px, ${height}px, ${height}px)`;
      } else {
        menuWrapper.style.paddingBottom = '';
        menuWrapper.style.height = '';
      }
  }

  var top = $('.search-recommendations-top')[0].offsetHeight + 24;
  if (!isMobile) {
      leftContainerElement.style.height = `clamp(300px, ${suggestionsWrapper - 60 - top}px, ${suggestionsWrapper - 60 - top}px)`;
  }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();

    $.spinner().stop();

    if (!(typeof (response) === 'object')) {
        $suggestionsWrapper.append(response).show();
        
        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $('.suggestions .item');
        if ($(suggestionsList).length) {
            $('input.search-field').attr('aria-describedby', 'search-result-count');
        } else {
            $('input.search-field').removeAttr('aria-describedby');
        }
    } else {
        $suggestionsWrapper.hide();
    }

    $('.search-results').removeClass('d-none');

    if (isMobile) {
      $('.suggestions-wrapper-container .page-search-result-container').addClass('d-none');
    }

    handleContainerSize(true);
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
      $('.trigger-search-link').attr('href', '');
      $(scope).attr('aria-activedescendant', '');
      $('.input-reset').removeClass('d-none');
      $('.recent-searches').addClass('d-none');
      var gcat = document.body.dataset.gcat;
        $.spinner().start();
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()) + '&qbrand=' + encodeURIComponent(gcat),
            method: 'GET',
            success: processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        getSuggestionsWrapper(scope).empty();
        $('.search-results').addClass('d-none');
        $('.recent-searches').removeClass('d-none');
        if (isMobile) {
          $('.suggestions-wrapper-container .page-search-result-container').removeClass('d-none');
          $('.input-reset').addClass('d-none');
        }
        handleContainerSize(false);
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(scope,direction) {
    // get all li elements in the suggestions list
    var suggestionsList = $('.suggestions .item');
    if (suggestionsList.filter('.selected').length === 0) {
        suggestionsList.first().addClass('selected');
        $(scope).each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected');
                $(this).removeAttr('aria-selected');
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass('selected');
                    suggestionsList.eq(idx).attr('aria-selected', true);
                    $(this).removeProp('aria-selected');
                    $(scope).each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
                    });
                } else {
                    suggestionsList.first().addClass('selected');
                    suggestionsList.first().attr('aria-selected', true);
                    $(scope).each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
}

function handleEnter(scope) {
  if($(scope).attr('aria-activedescendant').length === 0 && getSuggestionsWrapper(scope).html() !== "" ) {
    getSuggestionsWrapper(scope).empty();
    $('.trigger-search-link').attr('href', '');
  } else if(getSuggestionsWrapper(scope).html() !== "" ) {
    var linkText = $('#'+$(scope).attr('aria-activedescendant') + ' a div div span.type-global-control-productive-3').html().trim();
    var link = $('#'+$(scope).attr('aria-activedescendant') + ' a').attr('href');
    $('.trigger-search-link').attr('href', link);
    $(scope).val(linkText);
    getSuggestionsWrapper(scope).empty();
  } 
  
  return false;
}

module.exports = function () {
 

    $('form[name="simpleSearch"]').on('submit',function (e) {
        var suggestionsList = $('.suggestions .item');
        if (suggestionsList.filter('.selected').length !== 0) {
            e.preventDefault();
            suggestionsList.filter('.selected').find('a')[0].click();
        } else{
          if( $('.mm-menu-search__results__end').length>0){
            $('.mm-menu-search__results__end').find('a')[0].click();
          }
         
        }
        
    });

    $('.input-reset').on('click',function (e) {
      $('input.search-field')[0].value = '';
      getSuggestions('input.search-field');
  });

    $('input.search-field').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keyup', function (e) {
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                   handleArrow(this, DIRECTION_DOWN);
                   e.preventDefault(); // prevent moving the cursor
                   break;
                case UP_KEY:
                   handleArrow(this, DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case ESC:
                  if($(this).hasClass('accessibility-search-input')) {
                    $('.js-search-close').trigger('click');
                    e.preventDefault();
                    break;
                  }
                default:
                  if(e.which !== ENTER) {
                    debounceSuggestions(this, e.which);
                  }  
            }
        });

        $(this).on('keypress', function (e) {
          switch (e.which) {
            case ENTER:
              if(!window.isMobile() && (window.country === 'US' || window.country === 'CA') && $(this).attr('aria-activedescendant') !== 'show-all' && $(this).hasClass('accessibility-search-input')) {
              try {
                e.preventDefault()
                if(getSuggestionsWrapper(this).html() === "" ) {
                  debounceSuggestions(this, e);
                } else {
                  handleEnter(this);
                }
              } catch(err) {
                console.log(err.message); 
              }
            } else {
              if($(this).attr('aria-activedescendant').length === 0) {
                $(this).closest('form').submit();
              } else {
                e.preventDefault();
                $('#'+$(this).attr('aria-activedescendant') + ' a')[0].click();
              } 
            }
              break;
            }
        });
    });

    $('.mm-btn-accessibility-search').on('keyup', function(e) {
      if(e.which === ENTER) {
        if($('.trigger-search-link').attr('href').length > 0) {
          location.href = $('.trigger-search-link').attr('href') ;
        } else {
          $('.mm-menu-search__bar').submit();
        }
      }
    });

    $('.mm-btn-accessibility-search').on('click', function(e) {
      if($('.trigger-search-link').attr('href').length > 0) {
        location.href = $('.trigger-search-link').attr('href');
      } else {
        $('.mm-menu-search__bar').submit();
      }
    });

    $('body').on('keyup', function(e) {
      if($('.mm-menu__wrapper.js-menu-wrapper').hasClass('search-is-open') && e.which === ESC) {
        $('.js-search-close').trigger('click');
      }
    });
   

};
