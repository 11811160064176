function countries(value) {
  var continents = {'europe': 0, 'asia': 0, 'america': 0, 'africa': 0, 'oceania': 0, 'worldwide': 0};
  // Declare variables
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById('search-countries');
  filter = input.value.toUpperCase();
  ul = document.getElementById("country-list-wrapper");
  li = ul.getElementsByTagName('li');

  // Loop through all list items, and hide those who don't match the search query
  for (i = 0; i < li.length; i++) {
    if (value && filter != '') {
      a = li[i];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1 && txtValue.toUpperCase().charAt(txtValue.toUpperCase().indexOf(filter) - 1) == ' ') {
        var parent = li[i].parentElement.parentElement.id;
        ++continents[parent];
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    } else {
      li[i].style.display = "";
    }
  }

  var continentsAvailable = ['europe', 'asia', 'america', 'africa', 'oceania', 'worldwide'];
  continentsAvailable.forEach( name => {
    if ($('#' + name)[0]) {
      if (continents[name] > 0 && filter != '') {
        $('#' + name)[0].classList.add('is-active');
        $('#' + name)[0].classList.add('is-visible');
      } else {
        $('#' + name)[0].classList.remove('is-active');
        $('#' + name)[0].classList.remove('is-visible');
      }
    }
  });
}

// clear country search
$('body').on('click', '.js-country-clear', function(){
  $('#search-countries')[0].value = '';
  var value = false;
  var accordionItems = $('.accordion-group-list-item');
  countries(value);
  for (i = 0; i < accordionItems.length; i++) {
    accordionItems[i].classList.remove('is-active');
    accordionItems[i].classList.remove('is-visible');
  }
});

$('body').on('keyup change', '.search-countries', function (){
  var value = true;
  countries(value);
});
