
if ($('.notificationSwiper')) {
  var swiper = new Swiper(".notificationSwiper", {
    cssMode: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination-banner",
    },
    mousewheel: true,
    keyboard: true,
  });
}
