const header = document.querySelector('header.mm-menu.js-main-nav');

// Add as much padding top as header height to main element in order to menu and page title avoid overlap
const mainContentSpacer = () => {
  //TODO: fix when menu will be there??
  if(header){
    document.documentElement.style.setProperty('--header-height', header.clientHeight + 'px');
  }
}

const videoButtonsHide =() =>{
  let videos = document.querySelectorAll('video');

  setTimeout(() => {
    videos.forEach(function (video) {
      const ampElement = video.closest('.azuremediaplayer');
      ampElement && ampElement.classList.add('hide-buttons');
    });
  }, 1000);
  
}


const initialHeight = () =>{
  let el = document.querySelector("html");
  const vhInitial = .01 * window.innerHeight;
  el.style.setProperty("--vh-initial", vhInitial + "px");
  let vh = vhInitial
    , set = !1;
  window.addEventListener("resize", ()=>{
      var e = .01 * window.innerHeight;
      e === vh || set || (set = !0,
      vh = e,
      el.style.setProperty("--vh", vh + "px"))
  }
  )
}
           
module.exports = {
  mainContentSpacer: mainContentSpacer,
  initialHeight: initialHeight,
  // videoButtonsHide:videoButtonsHide
}