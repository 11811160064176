const selects = document.querySelectorAll('.mm-form-select select');

if(selects) {
  selects.forEach(s => {
    if(s.querySelector('option[value=""]') && s.querySelector('option[selected]') && s.querySelector('option[disabled]')) {
      s.closest('.mm-form-select').classList.add('-hidden-floating-label');
      s.addEventListener('change', () => {
        s.closest('.mm-form-select').classList.remove('-hidden-floating-label')
      });
    }
  });
}

